<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            placeholder="Search by Name/Product SKU"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary me-2" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-product' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ constants.general.ADD }}
        </button>
        <a
          href="javascript:void(0);"
          class="btn btn-purple"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
          v-if="permission.isImportAllowed || permission.isImportHistoryAllowed"
          ><i class="bi bi-file-earmark-arrow-up fs-4"></i> {{ constants.product.IMPORT_PRODUCT }}
          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <!--begin::Menu-->
        <div
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-purple fw-bold fs-7 w-250px py-4"
          data-kt-menu="true"
          
        >
          <!--begin::Menu item-->
          <div class="menu-item px-3" v-if="permission.isImportAllowed">
            <a @click="$router.push({ name: 'import-products' })" class="menu-link px-3" 
              ><i class="bi bi-file-earmark-arrow-up fs-4"></i>&nbsp; {{ constants.product.IMPORT_PRODUCT }}</a
            >
          </div>
          <!--end::Menu item-->
          <!--begin::Menu item-->
          <div class="menu-item px-3" v-if="permission.isImportHistoryAllowed">
            <a @click="$router.push({ name: 'import-products-history' })" class="menu-link px-3"
              ><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ constants.product.IMPORT_PRODUCT_HISTORY }}</a
            >
          </div>
          <!--end::Menu item-->
        </div>
        <!--end::Menu-->
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        class="align-middle"
        :order="searchParams.sortDesc"
        @sort="handleSortChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-name="{ row: data }">
          <div v-if="permission.isViewAllowed">
            <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: data.product_id } })">{{ data.name }}</a>
          </div>
          <div v-else>
            {{ data.name }}
          </div>
        </template>
        <template v-slot:cell-sku_code="{ row: data }">
          <div v-if="permission.isViewAllowed">
            <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: data.product_id } })">{{ data.sku_code }}</a>
          </div>
          <div v-else>
            {{ data.sku_code }}
          </div>
        </template>
        <template v-slot:cell-sizes="{ row: data }">
          <span v-for="(size, index) in data.sizes" :key="index">
            <span class="badge badge-light-primary m-1"> {{ size }} </span>
          </span>
        </template>
        <template v-slot:cell-sort_order="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-input-number
              v-model="data.sort_order"
              size="medium"
              controls-position="right"
              @change="changeSortOrder($event, data.product_id)"
            />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-status="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-switch
              v-model="data.status"
              @change="updateStatus($event, data.product_id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isViewAllowed || permission.isEditAllowed || permission.isAssignOptionAllowed || permission.isGenerateSKUAllowed || permission.isDuplicateAllowed || permission.isDeleteAllowed">
            <a href="javascript:void(0);" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :id="'menuAction' + data.product_id" >Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                </svg>
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4" data-kt-menu="true" v-loading="cellLoading" >
              <div class="menu-item px-3" v-if="permission.isViewAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: data.product_id } })"> <i class="far fa-eye"></i> &nbsp; {{ constants.general.VIEW }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isEditAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="update(data.product_id)"><i class="bi bi-pencil-square"></i> &nbsp; {{ constants.general.EDIT }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isAssignOptionAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="assignAdditionalOptions(data.product_id)"><i class="bi bi-card-checklist"></i> &nbsp; {{ constants.product.ASSIGN_PRODUCT_OPTIONS }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isGenerateSKUAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.stop="generateProductSku(data.product_id)"><i class="bi bi-upc-scan"></i> &nbsp; {{ constants.product.GENERATE_SKU }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isDuplicateAllowed">
                <a href="javascript:void(0);"  class="menu-link px-3" @click.prevent="duplicate(data)"><i class="far fa-clone"></i> &nbsp; {{ constants.general.DUPLICATE }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isDeleteAllowed">
                <a href="javascript:void(0);" class="menu-link px-3 text-danger" @click.prevent="remove(data.product_id, tableData.findIndex((i) => i.id === data.product_id))"><i class="bi bi-trash text-danger"></i> &nbsp; {{ constants.general.DELETE }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from 'element-plus';
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "product-listing",
  components: {
    Datatable,
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const updateProps = ref(null);
    const route = useRoute();
    const constants = globalConstant;
    const noDataFound = ref(globalConstant.general.LOADING);
    const cellLoading = ref(false);
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      sortBy: "sort_order",
      sortDesc: "asc"
    });
    
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
      isImportAllowed: false,
      isImportHistoryAllowed: false,
      isViewAllowed: false,
      isDuplicateAllowed: false,
      isGenerateSKUAllowed: false,
      isAssignOptionAllowed: false
    })
    const tableHeader = ref([
      {
        name: constants.general.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: constants.general.NAME,
        key: "name",
        sortable: true,
        width: "w-200px",
      },
      {
        name: constants.product.PRODUCT_SKU,
        key: "sku_code",
        width: "w-100px",
        sortable: true,
      },
      {
        name: constants.product.PRODUCT_SIZES,
        key: "sizes",
        width: "w-350px",
        sortable: false,
      },
      {
        name: "Sort Order",
        key: "sort_order",
        width: "w-100px",
        sortable: true,
      },
      {
        name: constants.general.STATUS,
        key: "status",
        width: "w-50px",
        sortable: false,
      },
      {
        name: constants.general.ACTIONS,
        key: "actions",
        width: "w-100px",
        alignment: 'text-center',
        sortable: false,
      },
    ]);

    //Page size change event
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = async(number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      await getProducts();
      MenuComponent.reinitialization();
    };

    //Handle search event
    const handleSearch = () => {
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      handleCurrentChange(1);
    };

    //Handle sort change event
    const handleSortChange = async(obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      await getProducts();
    }

    //Reset search value
    const resetSearch = async() => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
        store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        await getProducts();
        MenuComponent.reinitialization();
      }
    };

    //Get all products
    const getProducts = async () => {
      loading.value = true;
      await ApiService.query("products", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            data.data.data.forEach((obj) => {
              let size = [];
              obj.product_sizes = obj.sizes;
              obj.sizes.forEach((sizeObj) => {
                size.push(sizeObj.size);
              });
              obj.sizes = size;
              obj.status = obj.status == 1 ? true : false;
            });
            tableData.value = data.data.data;
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, globalConstant.general.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          tableData.value = [];
          loading.value = false;
          let message = '';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    };

    //Change Product Sort Order
    const changeSortOrder = (number, id) => {
      ApiService.post("products/sort-order-update", {
        number: number,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Update product status action from listing page
    const updateStatus = (status, id) => {
      ApiService.post("products/statusupdate", {
        status: status,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Duplicate product action from listing page
    const duplicate = async (itemDetails) => {
      itemDetails.product_sizes.forEach((obj) => {
        obj.sku_code = obj.sku_code;
        obj.shipping_package_id = '';
        obj.internal_package_id = '';
        obj.external_package_id = '';
      });
      ElMessageBox.confirm(
        'Do you really want to duplicate this record?<br> <strong>Note: This will duplicate size & options records related to this product. System will add the "D" in Product SKU and "Copy" in Product name for user identification.</strong>',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          center: true,
        }
      )
      .then(() => {
        ApiService.post("products", {
        type: "D",
        ref_id: itemDetails.product_id,
        name: itemDetails.name+ ' Copy',
        description: itemDetails.description,
        sort_order: itemDetails.sort_order,
        sku_generate_flag: itemDetails.sku_generate_flag,
        sku_code: itemDetails.sku_code + 'D',
        status: itemDetails.status,
        product_sizes: itemDetails.product_sizes,
      })
        .then(({ data }) => {
          if (data) {
            notificationFire(data.message, "success");
            router.push({
              name: "edit-product",
              params: { id: data.data.id },
            });
          }
        })
        .catch(({ error }) => {
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
      });
    };

    //Remove product action from listing page
    const remove = async (productID, index) => {
      ElMessageBox.confirm(
        'Do you really want to delete this record?<br> <strong>Note: This will delete size, options, weight, SKU & shipping package records related to this product.</strong>',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          center: true,
        }
      )
      .then(() => {
        ApiService.delete("products/" + productID)
        .then((response) => {
          tableData.value.splice(index, 1);
          notificationFire(response.data.message, "success");
          getProducts();
        })
        .catch((error) => {
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
      });
    };

    //View button action from listing page
    const open = (url) => {
      window.open(url, "_blank");
    };

    //Edit button action from listing page
    const update = (productID) => {
      router.push({ name: "edit-product", params: { id: productID } });
    };

    //Additional Option action from listing page
    const assignAdditionalOptions = (productID) => {
      router.push({
        name: "assign-product-options",
        params: { id: productID },
      });
    };

    //Generate Sku Codes
    const generateProductSku = async (productID) => {
      cellLoading.value = true;
      await ApiService.post("productsku/generate-sku", { 'product_id': productID })
        .then(({ data }) => {
          if(data) {
            notificationFire(data.message, "success");
          }
          cellLoading.value = false;
          document.getElementById('menuAction' + productID).click();
        })
        .catch((error) => {
          cellLoading.value = false;
          document.getElementById('menuAction' + productID).click();
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    }

    //Set breadcrumbs and get the products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createProduct") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateProduct") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteProduct") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      if (per.includes("importProduct") || role == "Super Admin")
        permission.isImportAllowed = true;
      if (per.includes("importProductHistory") || role == "Super Admin")
        permission.isImportHistoryAllowed = true;
      if (per.includes("viewProduct") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("duplicateProduct") || role == "Super Admin")
        permission.isDuplicateAllowed = true;
      if (per.includes("generateSKUProduct") || role == "Super Admin")
        permission.isGenerateSKUAllowed = true;
      if (per.includes("assignOptionProduct") || role == "Super Admin")
        permission.isAssignOptionAllowed = true

      await getProducts();
      MenuComponent.reinitialization();
    })

    return {
      tableData,
      getProducts,
      total,
      handleSizeChange,
      handleCurrentChange,
      loading,
      updateStatus,
      open,
      update,
      duplicate,
      remove,
      assignAdditionalOptions,
      handleSearch,
      resetSearch,
      generateProductSku,
      noDataFound,
      updateProps,
      constants,
      searchParams,
      tableHeader,
      cellLoading,
      permission,
      handleSortChange,
      changeSortOrder
    };
  },
};
</script>
<style>
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(2.1em + 1.1rem + 2px);
    width: calc(2.1em + 1.1rem + 2px);
  }
  .btn.btn-purple{
    background-color: #8618cbbf;
    border-color: #8618cbbf;
    color:#ffffff;
  }
  .btn.btn-purple i{color:#ffffff;}
  .btn.btn-purple svg path{fill:#ffffff !important;}
  .btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
    background-color: #6911a1bf;
    border-color: #6911a1bf;
  }
  .menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: #f5e7ff96;
    color: #8618cbbf;
  }
</style>